/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import Pagina from '../components/Pagina'
import './covid.css'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function Covid ({ data }) {
  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <div className="container-fluid px-0 d-none d-md-block">
        <GatsbyImage
          image={data.bannerCovid.childImageSharp.gatsbyImageData}
          className="w-100"
          title="Banner - Covid-19"
          alt="Especial Coronavirus" />
      </div>
      <div className="container-fluid px-0 d-block d-md-none">
        <GatsbyImage
          image={data.bannerCovidMobile.childImageSharp.gatsbyImageData}
          className="w-100"
          title="Banner - Covid-19"
          alt="Especial Coronavirus" />
      </div>
      <div className="container-fluid bg-danger">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="theme-secundary py-3 text-light" style={{ fontWeight: 900 }}>Novo teste rápido para COVID-19</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center d-lg-none mt-lg-4">
            <GatsbyImage
              image={data.testeCovid.childImageSharp.gatsbyImageData}
              className="pb-lg-3 w-100"
              title="Banner - Medicamento"
              alt="Especial Coronavirus" />
          </div>
          <div className="col-lg-6 p-0">
            <div className="col-lg-12">
              <h2 className="theme-secundary mt-4">Sobre os testes rápidos</h2>
              <span>
                Pensando no melhor custo-benefício para o cliente, a Droga Leste passou a comercializar um novo teste rápido para diagnóstico preliminar da COVID-19. Trata-se do teste <span className="font-weight-bold"> Rápido Covid-19 15 minutos Nutriex COVID-19 IgG/IgM </span>
              </span>Teste Kit (Colloidal Gold Method).
            </div>
            <div className="col-lg-12">
              <h2 className="theme-secundary mt-4">Como o teste funciona?</h2>
              <span>
                O teste Rápido Covid-19 15 minutos Nutriex COVID-19 IgG/IgM Teste Kit (Colloidal Gold Method) é um imunoensaio Cromatográfico de fase sólida para a detecção rápida, qualitativa e diferencial de anticorpos IgG e IgM para COVID-19. Este teste fornece apenas um resultado preliminar, portanto, qualquer amostra reativa com a COVID19 IgG/IgM Teste Kit deve ser confirmada com método(s) de teste alternativo(s) e achados clínicos.
              </span>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block mt-4 mt-lg-4">
            <GatsbyImage
              image={data.testeCovid.childImageSharp.gatsbyImageData}
              className="pb-3 w-100"
              title="Banner - Medicamento"
              alt="Especial Coronavirus" />
          </div>
          <div className="col-lg-12">
            <h2 className="theme-secundary mt-3">Quem pode fazer?</h2>
            <span>
               Qualquer pessoa com suspeita de estar com COVID-19 pode fazer, desde que siga alguns critérios. Para mais informações, converse com um de nossos farmacêuticos.
            </span>
          </div>
          <div className="col-lg-12">
            <h2 className="theme-secundary mt-4">Quanto custa?</h2>
            <span>
              <span className="font-weight-bold">Em até 5x de 27,99 ou R$139,99 a vista.</span>
            </span>
          </div>
          <div className="col-lg-12">
            <h2 className="theme-secundary mt-4">Preciso agendar?</h2>
            <span>
              <span className="font-weight-bold">O agendamento não é obrigatório, mas é recomendável.
              </span> A fim de evitar aglomeração de pessoas nas lojas nas lojas, confirme antes por telefone ou whatsapp o melhor horário para realizar o seu teste.<br />
            </span>
          </div>
          <div className="col-lg-12 mt-3">
            <span className="mt-5">
              Lembramos que o uso de máscara em nossas lojas é obrigatório, respeitando determinação do governo do Estado de São Paulo.
            </span>
          </div>
          <div className="col-lg-12">
            <h2 className="theme-secundary mt-4">Onde o teste está disponível?</h2>
            <span>
              Todas as lojas da Droga Leste, exceto:
            </span>
          </div>
          <div className="col-lg-12 mt-3">
            Droga Leste 15 - Limoeiro<br />
            Droga Leste 40 – Cidade Tiradentes (popular)
          </div>
          <div className="col-md-12 mt-3 mb-4">
            <div className="row">
              <div className="col-xl-6 col-lg-5 mr-xl-5">
                <h2 id="responsive" className="theme-secundary pt-md-2 mb-4 mb-md-0">
                  Entre em contato com a loja mais próxima:
                </h2>
              </div>
              <div className="col-xl-2 col-lg-3 ml-xl-5 mt-md-2">
                <Link to="/lojas/" id="loja-font" title="Acessar Loja" className="btn-sm d-flex justify-content-center align-items-center theme-border-2 theme--secundary text-white text-uppercase px-3 py-2">Clique aqui</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/covid/"}) {
    path
    metaTitle
    metaDescription
    tags
  }
  testeCovid: file(relativePath: {eq: "images/all/img-teste-covid.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerCovid: file(relativePath: {eq: "images/desktop/Especial-CoronaVirus.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerCovidMobile: file(relativePath: {eq: "images/mobile/corona-mobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
